import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

function ArtPage() {
    return(
        <>
         <NavBar/>
         <h1> Art</h1>
         <Footer/>
        </>
    ) 
}

export default ArtPage