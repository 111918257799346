import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

function AboutPage() {
    return(
        <>
        <NavBar/>
        <h2> About Page</h2>
        <Footer/>
        </>
    )
}
export default AboutPage