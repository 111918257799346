import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

function Store() {
    return(
        <>
         <NavBar/>
         <h1> STORE</h1>
         <Footer/>
        </>
    ) 
}

export default Store