import React from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

function ContactPage() {
    return(
        <>
        <NavBar/>
        <Footer/>
        </>
    ) 
}

export default ContactPage